import React from "react";
import { Link } from "gatsby";

import "./projCard.scss";

const ProjCard = ({ proj }) => {
  const { img, title, description, slug } = proj;
  const images = {
    src: [
      "https://images.unsplash.com/photo-1512917774080-9991f1c4c750?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1050&q=80",
      "https://images.unsplash.com/photo-1512916194211-3f2b7f5f7de3?ixlib=rb-1.2.1&auto=format&fit=crop&w=1050&q=80",
      "https://images.unsplash.com/photo-1512914890251-2f96a9b0bbe2?ixlib=rb-1.2.1&auto=format&fit=crop&w=1951&q=80",
      "https://images.unsplash.com/photo-1512918428767-68579e250c06?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1950&q=80",
      "https://images.unsplash.com/photo-1512916958891-fcf61b2160df?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1951&q=80",
      "https://images.unsplash.com/photo-1512918728675-ed5a9ecdebfd?ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    ],
    orig: "https://unsplash.com/photos/2d4lAQAlbDA",
    author: "Photo by iAlicante Mediterranean Homes on Unsplash",
  };
  console.log(img);
  return (
    <div className="proj-card">
      {/* <Link to={`/projects/${slug}/#single-project`}></Link> */}
      <Link>
        <img src={img.src[0]} alt={img.orig} />
        {/* <section className="wrap-dark">
          <main className="proj-content text-center">
            <h4>{title}</h4>
            <p>{description}</p>
          </main>
        </section> */}
      </Link>
    </div>
  );
};

export default ProjCard;
